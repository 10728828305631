import React from "react";
import SignoutButton from "../auth/SignoutButton";
import Spinner from "react-bootstrap/Spinner";
import NavWrapper from "../nav/NavWrapper";
import ReportingView from "./reporting/Reporting";
import startOfPreviousMonday from "./startOfPreviousMonday";
import TimesheetHistory from "./history/TimesheetHistory";
import useCollectionSubscription from "@wellingtonsteve/jscommon/useCollectionSubscription";
import useCutoffDate from "@wellingtonsteve/jscommon/useCutoffDate";

const If = NavWrapper.If;

const Loading = () => (
  <div>
    <Spinner animation="grow" variant="danger" /> <Spinner animation="grow" variant="warning" />{" "}
    <Spinner animation="grow" variant="info" /> <Spinner animation="grow" variant="success" />
  </div>
);

const LoadingWithCaption = ({ caption }) => (
  <div style={{ width: "100%", textAlign: "center", margin: "1rem" }}>
    <Loading />
    <h1>{caption}</h1>
  </div>
);

const defaultCutOffDate = () => startOfPreviousMonday(new Date(), 2);

const LoggedInApp = ({ logOutAction, currentUser, datasetDoc }) => {
  if (datasetDoc === undefined) {
    return <LoadingWithCaption caption={"Hi " + currentUser.displayName + ". Logging in..."} />;
  } else {
    return (
      <DatasetDocLoaded
        logOutAction={logOutAction}
        currentUser={currentUser}
        datasetDoc={datasetDoc}
      />
    );
  }
};

const DatasetDocLoaded = ({ logOutAction, currentUser, datasetDoc }) => {
  const { cutOffDate, cutOffCollection, cutoffActions } = useCutoffDate(
    7,
    defaultCutOffDate,
    datasetDoc,
    "content"
  );
  const [contentDocs, loadingMore] = useCollectionSubscription(
    cutOffCollection,
    undefined,
    "content (" + cutOffDate + ")"
  );

  const [todoDocs] = useCollectionSubscription(datasetDoc, "todos");
  const [categoryDocs] = useCollectionSubscription(datasetDoc, "categories");

  if (categoryDocs === undefined || contentDocs === undefined || todoDocs === undefined) {
    return <LoadingWithCaption caption={"Hi " + currentUser.displayName + ". Loading..."} />;
  }

  const categories = categoryDocs.reduce(
    (previous, current) => ({ ...previous, [current.id]: current.data() }),
    {}
  );

  return (
    <NavWrapper title={"Timesheet (" + datasetDoc.id + ")"}>
      <If
        tabName="Timesheet"
        default
        ifSelectedShow={() => (
          <TimesheetHistory
            categoryDocs={categoryDocs}
            categories={categories}
            contentDocs={contentDocs}
            contentCollection={datasetDoc.collection("content")}
            cutOffDate={cutOffDate}
            loadingMore={loadingMore}
            cutoffActions={cutoffActions}
          />
        )}
      />
      <If
        tabName="Reporting"
        ifSelectedShow={() => (
          <ReportingView
            categoryDocs={categoryDocs}
            contentDocs={contentDocs}
            cutOffDate={cutOffDate}
            loadingMore={loadingMore}
            cutoffActions={cutoffActions}
          />
        )}
      />
      <SignoutButton onClick={logOutAction} />
    </NavWrapper>
  );
};

export default LoggedInApp;
