import React from "react";
import Table from "react-bootstrap/Table";
import TimeDate from "../common/timeDate";
import DateSelector from "../common/DateSelector";
import EntryTag from "../common/EntryTag";
import { sorted, sum, unique } from "@wellingtonsteve/jscommon/arrayOps";

const makeAugmentedEntry = entry => {
  const startTime = entry.startTime.toDate();
  const startDate = new Date(startTime.getFullYear(), startTime.getMonth(), startTime.getDate());
  const startDateTimestamp = startDate.getTime();
  return {
    ...entry,
    formattedStartDate: TimeDate.formatDate(startDate),
    startDate: startDate,
    startDateTimestamp: startDateTimestamp,
    durationInSecs: Math.round((entry.endTime.toMillis() - entry.startTime.toMillis()) / 1000),
  };
};

const binarySearch = (lowerBound, upperBound, depth, calculation, compare) => {
  const scalar = (lowerBound + upperBound) / 2;
  const calculationResult = calculation(scalar);
  const comparison = compare(calculationResult);
  if (comparison === 0 || depth > 10) {
    return calculationResult;
  } else if (comparison < 0) {
    // too small, raise the lower bound
    return binarySearch(scalar, upperBound, depth + 1, calculation, compare);
  } else {
    // too big, lower the upper bound
    return binarySearch(lowerBound, scalar, depth + 1, calculation, compare);
  }
};

const groupData = completedEntries => {
  const entries = completedEntries
    .map(makeAugmentedEntry)
    .filter(augmentedEntry => augmentedEntry.durationInSecs > 0);

  const formattedDates = sorted(unique(entries.map(entry => entry.startDateTimestamp)))
    .map(time => new Date(time))
    .map(date => TimeDate.formatDate(date));
  const categoryKeys = sorted(unique(entries.map(entry => entry.categoryKey)));

  const hoursToSecs = hours => hours * 60 * 60;
  const roundToQuarterHour = seconds => Math.ceil(seconds / (15 * 60)) * (15 * 60);

  const calculateForDate = formattedDate => {
    const entriesForThisDate = entries.filter(entry => entry.formattedStartDate === formattedDate);
    const dateTotal = sum(entriesForThisDate.map(entry => entry.durationInSecs));
    const initialLowerBound = hoursToSecs(1) / dateTotal;
    const initialUpperBound = hoursToSecs(11) / dateTotal;
    const calculateForDateWithScalar = scalar =>
      categoryKeys.map(categoryKey => {
        const actualTotal = sum(
          entriesForThisDate
            .filter(entry => entry.categoryKey === categoryKey)
            .map(entry => entry.durationInSecs)
        );
        return {
          formattedDate: formattedDate,
          categoryKey: categoryKey,
          actualTotal: actualTotal,
          scaledTotal: roundToQuarterHour(actualTotal * scalar),
        };
      });
    const compare = calculationResult => {
      const actualDayTotal = sum(calculationResult.map(forCategory => forCategory.scaledTotal));
      const expectedDatTotal = hoursToSecs(7);
      return actualDayTotal - expectedDatTotal;
    };
    return binarySearch(
      initialLowerBound,
      initialUpperBound,
      0,
      calculateForDateWithScalar,
      compare
    );
  };

  const dateCategoryData = formattedDates.flatMap(formattedDate => calculateForDate(formattedDate));

  const coalesceDataWhere = predicate => ({
    actualTotal: sum(dateCategoryData.filter(predicate).map(group => group.actualTotal)),
    scaledTotal: sum(dateCategoryData.filter(predicate).map(group => group.scaledTotal)),
  });

  const dateTotals = formattedDates.map(formattedDate => ({
    formattedDate: formattedDate,
    ...coalesceDataWhere(cross => cross.formattedDate === formattedDate),
  }));
  const categoryTotals = categoryKeys.map(categoryKey => ({
    categoryKey: categoryKey,
    ...coalesceDataWhere(cross => cross.categoryKey === categoryKey),
  }));
  const grandTotal = coalesceDataWhere(() => true);
  return {
    formattedDates: formattedDates,
    categoryKeys: categoryKeys,
    groups: [...dateCategoryData, ...dateTotals, ...categoryTotals, grandTotal],
  };
};

const ReportingView = ({
  categoryDocs,
  contentDocs,
  loadingMore,
  cutOffDate,
  cutoffActions,
  week,
}) => {
  const completedEntries = contentDocs
    .map(doc => doc.data())
    .filter(entryObj => entryObj.endTime !== undefined);

  const onlyUnique = (value, index, self) => self.indexOf(value) === index;
  const mondays = !!week
    ? [week]
    : completedEntries
        .map(entry => {
          const date = entry.startTime.toDate();
          const mondayOffset = date.getDay() >= 1 ? date.getDay() - 1 : 6 + date.getDay();
          return new Date(
            date.getFullYear(),
            date.getMonth(),
            date.getDate() - mondayOffset
          ).getTime();
        })
        .filter(onlyUnique)
        .sort()
        .map(millis => new Date(millis))
        .reverse();

  return (
    <div>
      {mondays.map(monday => {
        return (
          <div key={monday}>
            <h1>w/c {TimeDate.formatDate(monday)}</h1>
            <ReportingTable
              completedEntries={completedEntries}
              monday={monday}
              categoryDocs={categoryDocs}
            />
          </div>
        );
      })}
      <hr />
      <DateSelector
        loadingMore={loadingMore}
        cutOffDate={cutOffDate}
        cutoffActions={cutoffActions}
      />
    </div>
  );
};

const ReportingTable = ({ completedEntries, monday, categoryDocs }) => {
  const thisWeekEntries = completedEntries.filter(entry => {
    const date = entry.startTime.toDate();
    const mondayOffset = date.getDay() >= 1 ? date.getDay() - 1 : 6 + date.getDay();
    const entryMonday = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate() - mondayOffset
    );
    const entryMondayMills = entryMonday.getTime();
    return entryMondayMills === monday.getTime();
  });
  const groupedData = groupData(thisWeekEntries);
  const findGroup = (formattedDate, categoryKey) =>
    groupedData.groups.find(
      group => group.formattedDate === formattedDate && group.categoryKey === categoryKey
    );
  const categories = categoryDocs.reduce(
    (previous, current) => ({ ...previous, [current.id]: current.data() }),
    {}
  );

  return (
    <div>
      <Table>
        <thead>
          <tr>
            <th />
            {groupedData.formattedDates.map(date => (
              <th key={date}>{date}</th>
            ))}
            <th style={{ fontWeight: "bold" }}>Total</th>
          </tr>
        </thead>
        <tbody>
          {groupedData.categoryKeys.map(categoryKey => {
            return (
              <tr key={categoryKey}>
                <td>
                  <EntryTag categories={categories} categoryKey={categoryKey} />
                </td>
                {groupedData.formattedDates.map(formattedDate => (
                  <td key={formattedDate}>
                    <DurationOrEmpty group={findGroup(formattedDate, categoryKey)} />
                  </td>
                ))}
                <td style={{ fontWeight: "bold" }}>
                  <DurationOrEmpty group={findGroup(undefined, categoryKey)} />
                </td>
              </tr>
            );
          })}
          <tr style={{ fontWeight: "bold" }}>
            <td>Total</td>
            {groupedData.formattedDates.map(formattedDate => (
              <td key={formattedDate}>
                <DurationOrEmpty group={findGroup(formattedDate, undefined)} />
              </td>
            ))}
            <td>
              <DurationOrEmpty group={findGroup(undefined, undefined)} />
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
};

// Extract following duplication
const ifNotZero = (num, suffix) => (num > 0 ? num + suffix : null);
const calculateFractionalHours = duration => Math.round((100 * duration) / 3600) / 100;
const Duration = ({ duration }) => {
  const fractionalHours = calculateFractionalHours(duration);

  const hours = Math.floor(duration / (60 * 60));
  const minutes = Math.floor((duration - hours * 60 * 60) / 60);

  if (hours === 0 && minutes === 0) {
    return <span title={fractionalHours}>{duration + "s"}</span>;
  }

  const hoursStr = ifNotZero(hours, "h");
  const minutesStr = ifNotZero(minutes, "m");
  return (
    <span title={fractionalHours}>
      {[hoursStr, minutesStr].filter(str => str !== null).join(" ")}
    </span>
  );
};
const DurationOrEmpty = ({ group }) => {
  const scaledFractional = calculateFractionalHours(group.scaledTotal);
  return group.actualTotal === 0 ? null : (
    <span>
      <span style={{ fontSize: "150%" }}>{scaledFractional}</span>{" "}
      <span style={{ fontSize: "80%", color: "lightslategray" }}>
        (<Duration duration={group.actualTotal} />){/*<br />*/}
      </span>
    </span>
  );
};

ReportingView.ReportingTable = ReportingTable;

export default ReportingView;
