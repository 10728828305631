import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import initFirebaseAppComponent from "@wellingtonsteve/jscommon/initFirebaseAppComponent";
import LoggedInApp from "./components/timesheet/LoggedInApp";

const FirebaseApp = initFirebaseAppComponent({
  apiKey: "AIzaSyAfrI60IRCijUnihsUUBa137a-iAxTTlho",
  authDomain: "timesheet-30dd3.firebaseapp.com",
  databaseURL: "https://timesheet-30dd3.firebaseio.com",
  projectId: "timesheet-30dd3",
  storageBucket: "timesheet-30dd3.appspot.com",
  messagingSenderId: "1060997579920",
  appId: "1:1060997579920:web:03dfd7884036fb617a8415",
});

ReactDOM.render(
  <FirebaseApp>
    <LoggedInApp />
  </FirebaseApp>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
