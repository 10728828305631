const weekdays = new Array(7);
weekdays[0] = "Sun";
weekdays[1] = "Mon";
weekdays[2] = "Tues";
weekdays[3] = "Weds";
weekdays[4] = "Thurs";
weekdays[5] = "Fri";
weekdays[6] = "Sat";

const formatDate = date => {
  let day = "" + date.getDate();
  let month = "" + (date.getMonth() + 1);
  let year = date.getFullYear();

  if (day.length < 2) day = "0" + day;
  if (month.length < 2) month = "0" + month;

  return weekdays[date.getDay()] + " " + [day, month, year].join("/");
};

const TimeDate = { formatDate };

export default TimeDate;
