import React from "react";

const EntryTag = ({ categories, categoryKey }) => (
  <div
    style={{
      fontSize: "100%",
      fontWeight: "bold",
      display: "inline-block",
      border: "1px solid",
      borderColor:
        categories[categoryKey].textColour === undefined
          ? categories[categoryKey].colour
          : categories[categoryKey].textColour,
      color:
        categories[categoryKey].textColour === undefined
          ? categories[categoryKey].colour
          : categories[categoryKey].textColour,
      margin: "0.1rem 0.3rem",
      padding: "0 0.3rem",
      marginRight: "0.3rem",
      marginLeft: "0.3rem",
    }}
    title={categories[categoryKey].name}
  >
    {categories[categoryKey].name}
  </div>
);

export default EntryTag;
