import React, { cloneElement, useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { ButtonGroup, Col, OverlayTrigger, Popover } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import Modal from "react-bootstrap/Modal";
import TimeDate from "../common/timeDate";
import DateSelector from "../common/DateSelector";
import EntryTag from "../common/EntryTag";
import FontAwesome from "@wellingtonsteve/jscommon/FontAwesome";
import {
  dateToTimestamp,
  secondsSince,
  timestampNow,
} from "@wellingtonsteve/jscommon/timestampUtils";
import ReportingView from "../reporting/Reporting";

const useModal = () => {
  const [modalContent, setModalContent] = useState(null);
  const modalElement =
    modalContent && cloneElement(modalContent, { handleClose: () => setModalContent(null) });
  return [modalElement, setModalContent];
}; //TODO: extract dupe

const TimesheetHistory = ({
  contentCollection,
  categories,
  categoryDocs,
  contentDocs,
  cutOffDate,
  cutoffActions,
  loadingMore,
}) => {
  const [isTimesheet, setTimesheet] = useState(true);
  const [modalElement, displayModal] = useModal();
  const docToAugmentedObject = entryDoc => {
    const startDate = entryDoc.data().startTime.toDate();
    const durationInSecsObj =
      entryDoc.data().endTime === undefined
        ? {}
        : {
            durationInSecs: Math.round(
              (entryDoc.data().endTime.toMillis() - entryDoc.data().startTime.toMillis()) / 1000
            ),
          };
    return {
      ...entryDoc.data(),
      id: entryDoc.id,
      formattedStartDate: TimeDate.formatDate(startDate),
      ...durationInSecsObj,
    };
  };

  const sortedEntries = contentDocs
    .map(docToAugmentedObject)
    .sort((entry1, entry2) => entry2.startTime._compareTo(entry1.startTime));

  const onlyUnique = (value, index, self) => self.indexOf(value) === index;
  const mondays = sortedEntries
    .map(entry => {
      const date = entry.startTime.toDate();
      const mondayOffset = date.getDay() >= 1 ? date.getDay() - 1 : 6 + date.getDay();
      return new Date(date.getFullYear(), date.getMonth(), date.getDate() - mondayOffset).getTime();
    })
    .filter(onlyUnique)
    .sort()
    .map(millis => new Date(millis))
    .reverse();

  const lastEntry = sortedEntries[0];
  const inProgressCategory =
    lastEntry === undefined || lastEntry.endTime !== undefined ? undefined : lastEntry;

  const [textContent, setTextContent] = useState("");

  return (
    <div>
      {modalElement}
      {categoryDocs
        .filter(categoryDoc => categoryDoc.data().archived !== true)
        .map(categoryDoc => (
          <StartStopButton
            key={categoryDoc.id}
            categoryObj={categoryDoc.data()}
            contentCollection={contentCollection}
            categoryKey={categoryDoc.id}
            inProgressCategory={inProgressCategory}
            textContent={textContent}
          />
        ))}
      <Form
        onSubmit={event => {
          event.preventDefault();
          event.stopPropagation();
        }}
      >
        <Form.Row>
          <Form.Group as={Col} controlId="description">
            <Form.Label>Description</Form.Label>
            <Form.Control
              value={textContent}
              onChange={event => setTextContent(event.target.value)}
            />
          </Form.Group>
          <ButtonGroup>
            <Button disabled={isTimesheet} onClick={() => setTimesheet(true)}>
              Timesheet
            </Button>
            <Button disabled={!isTimesheet} onClick={() => setTimesheet(false)}>
              Reporting
            </Button>
          </ButtonGroup>
        </Form.Row>
      </Form>
      {mondays.map(monday => {
        const formattedMonday = TimeDate.formatDate(monday);
        const formattedTuesday = TimeDate.formatDate(
          new Date(monday.getFullYear(), monday.getMonth(), monday.getDate() + 1)
        );
        const formattedWednesday = TimeDate.formatDate(
          new Date(monday.getFullYear(), monday.getMonth(), monday.getDate() + 2)
        );
        const formattedThursday = TimeDate.formatDate(
          new Date(monday.getFullYear(), monday.getMonth(), monday.getDate() + 3)
        );
        const formattedFriday = TimeDate.formatDate(
          new Date(monday.getFullYear(), monday.getMonth(), monday.getDate() + 4)
        );
        const formattedSaturday = TimeDate.formatDate(
          new Date(monday.getFullYear(), monday.getMonth(), monday.getDate() + 5)
        );
        const formattedSunday = TimeDate.formatDate(
          new Date(monday.getFullYear(), monday.getMonth(), monday.getDate() + 6)
        );
        return (
          <div key={monday}>
            <h1>w/c {formattedMonday}</h1>{" "}
            {isTimesheet ? (
              <div style={{ display: "flex" }}>
                <div style={{ flex: 1, padding: "2px" }}>
                  <Entries
                    key={formattedMonday}
                    sortedEntries={sortedEntries}
                    date={formattedMonday}
                    categories={categories}
                    setTextContent={setTextContent}
                    contentCollection={contentCollection}
                    inProgressCategory={inProgressCategory}
                    displayModal={displayModal}
                  />
                </div>
                <div style={{ flex: 1, padding: "2px" }}>
                  <Entries
                    key={formattedTuesday}
                    sortedEntries={sortedEntries}
                    date={formattedTuesday}
                    categories={categories}
                    setTextContent={setTextContent}
                    contentCollection={contentCollection}
                    inProgressCategory={inProgressCategory}
                    displayModal={displayModal}
                  />
                </div>
                <div style={{ flex: 1, padding: "2px" }}>
                  <Entries
                    key={formattedWednesday}
                    sortedEntries={sortedEntries}
                    date={formattedWednesday}
                    categories={categories}
                    setTextContent={setTextContent}
                    contentCollection={contentCollection}
                    inProgressCategory={inProgressCategory}
                    displayModal={displayModal}
                  />
                </div>
                <div style={{ flex: 1, padding: "2px" }}>
                  <Entries
                    key={formattedThursday}
                    sortedEntries={sortedEntries}
                    date={formattedThursday}
                    categories={categories}
                    setTextContent={setTextContent}
                    contentCollection={contentCollection}
                    inProgressCategory={inProgressCategory}
                    displayModal={displayModal}
                  />
                </div>
                <div style={{ flex: 1, padding: "2px" }}>
                  <Entries
                    key={formattedFriday}
                    sortedEntries={sortedEntries}
                    date={formattedFriday}
                    categories={categories}
                    setTextContent={setTextContent}
                    contentCollection={contentCollection}
                    inProgressCategory={inProgressCategory}
                    displayModal={displayModal}
                  />
                  <Entries
                    key={formattedSaturday}
                    sortedEntries={sortedEntries}
                    date={formattedSaturday}
                    categories={categories}
                    setTextContent={setTextContent}
                    contentCollection={contentCollection}
                    inProgressCategory={inProgressCategory}
                    displayModal={displayModal}
                  />
                  <Entries
                    key={formattedSunday}
                    sortedEntries={sortedEntries}
                    date={formattedSunday}
                    categories={categories}
                    setTextContent={setTextContent}
                    contentCollection={contentCollection}
                    inProgressCategory={inProgressCategory}
                    displayModal={displayModal}
                  />
                </div>
              </div>
            ) : (
              <ReportingView.ReportingTable
                categoryDocs={categoryDocs}
                completedEntries={contentDocs
                  .map(doc => doc.data())
                  .filter(entryObj => entryObj.endTime !== undefined)}
                monday={monday}
              />
            )}
          </div>
        );
      })}
      <hr />
      <DateSelector
        loadingMore={loadingMore}
        cutOffDate={cutOffDate}
        cutoffActions={cutoffActions}
      />
    </div>
  );
};

const Entries = ({
  sortedEntries,
  date,
  categories,
  setTextContent,
  contentCollection,
  inProgressCategory,
  displayModal,
}) => {
  const todayEntries = sortedEntries.filter(entryObj => entryObj.formattedStartDate === date);
  const todayCompletedEntries = todayEntries.filter(entryObj => entryObj.endTime !== undefined);
  const totalSecondsToday = todayCompletedEntries.reduce(
    (sum, entryObj) => sum + entryObj.durationInSecs,
    0
  );
  if (todayEntries.length === 0) {
    return null;
  }
  return (
    <div className="section">
      <Alert variant="secondary" className="sectionHeading">
        <h2>
          {date} • <Duration duration={totalSecondsToday} />
        </h2>
      </Alert>
      {todayEntries.map(entryObj => (
        <Entry
          key={entryObj.id}
          entryObj={entryObj}
          categories={categories}
          setTextContent={setTextContent}
          contentCollection={contentCollection}
          inProgressCategory={inProgressCategory}
          commitUpdates={newFields =>
            contentCollection.doc(entryObj.id).set(newFields, { merge: true })
          }
          deleteAction={() => contentCollection.doc(entryObj.id).delete()}
          displayModal={displayModal}
        />
      ))}
    </div>
  );
};
const FormattedTimestamp = ({ timestamp }) => {
  const date = timestamp.toDate();

  let hours = "" + date.getHours();
  let minutes = "" + date.getMinutes();

  if (hours.length < 2) hours = "0" + hours;
  if (minutes.length < 2) minutes = "0" + minutes;

  return (
    <span>
      {hours}:{minutes}
    </span>
  );
};

const Entry = ({
  entryObj,
  categories,
  setTextContent,
  contentCollection,
  inProgressCategory,
  commitUpdates,
  deleteAction,
  displayModal,
}) => {
  const isInProgress = entryObj.endTime === undefined;
  const textColour =
    categories[entryObj.categoryKey].textColour === undefined
      ? "darkslategrey"
      : categories[entryObj.categoryKey].textColour;

  const thisInProgress = inProgressCategory !== undefined && entryObj.id === inProgressCategory.id;
  const otherInProgress = inProgressCategory !== undefined && !thisInProgress;
  const endCurrent = now =>
    contentCollection.doc(inProgressCategory.id).set({ endTime: now }, { merge: true });
  const startNew = now =>
    contentCollection.add({
      startTime: now,
      categoryKey: entryObj.categoryKey,
      textContent: entryObj.textContent,
    });

  const popover = (
    <Popover id="popover-basic">
      <Popover.Title as="h3">
        <IdLabel id={entryObj.id} />
      </Popover.Title>
      <Popover.Content>
        <EditButton
          categories={categories}
          entryObj={entryObj}
          commitUpdates={commitUpdates}
          displayModal={displayModal}
        />
        <DeleteButton
          categories={categories}
          entryObj={entryObj}
          deleteAction={deleteAction}
          displayModal={displayModal}
        />
        <Button
          style={{
            marginLeft: "0.5rem",
            background: categories[entryObj.categoryKey].colour,
            color: textColour,
            borderColor: categories[entryObj.categoryKey].colour,
          }}
          onClick={() => {
            const post = () => setTextContent(entryObj.textContent);
            const now = timestampNow();
            if (thisInProgress) {
              endCurrent(now);
            } else if (otherInProgress) {
              endCurrent(now).then(() => startNew(now).then(post));
            } else {
              startNew(now).then(post);
            }
          }}
        >
          {thisInProgress ? (
            <FontAwesome.Stop />
          ) : otherInProgress ? (
            <FontAwesome.Switch />
          ) : (
            <FontAwesome.Start />
          )}{" "}
        </Button>
      </Popover.Content>
    </Popover>
  );

  return (
    <div>
      <OverlayTrigger rootClose trigger="click" placement="bottom" overlay={popover}>
        <span>
          <EntryTag categories={categories} categoryKey={entryObj.categoryKey} />
          {entryObj.textContent !== undefined && entryObj.textContent !== "" ? (
            <span>{entryObj.textContent} - </span>
          ) : null}
          <span style={{ color: "#f4eee8", fontSize: "90%" }}>
            <FormattedTimestamp timestamp={entryObj.startTime} /> -{" "}
            {isInProgress ? "..." : <FormattedTimestamp timestamp={entryObj.endTime} />} (
            {isInProgress ? (
              <TimeSince startDateTime={entryObj.startTime} />
            ) : (
              <Duration duration={entryObj.durationInSecs} />
            )}
            )
          </span>
        </span>
      </OverlayTrigger>
    </div>
  );
};

const DeleteModal = ({ categories, entryObj, deleteAction, handleClose }) => (
  <Modal show={true} onHide={handleClose} animation={false} centered style={{ color: "black" }}>
    <Modal.Header closeButton>
      <Modal.Title>
        {categories[entryObj.categoryKey].name}{" "}
        {entryObj.textContent !== undefined && entryObj.textContent !== ""
          ? "[" + entryObj.textContent + "] "
          : ""}
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>Are you sure?</Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={handleClose}>
        Cancel
      </Button>
      <Button variant="danger" onClick={() => deleteAction().then(handleClose)}>
        Yes, delete!
      </Button>
    </Modal.Footer>
  </Modal>
);

const DeleteButton = ({ categories, entryObj, deleteAction, displayModal }) => {
  return (
    <span>
      <Button
        variant="danger"
        style={{ marginLeft: "0.5rem" }}
        onClick={() =>
          displayModal(
            <DeleteModal entryObj={entryObj} categories={categories} deleteAction={deleteAction} />
          )
        }
      >
        <FontAwesome.Delete />
      </Button>
    </span>
  );
};

const EditModal = ({ entryObj, categories, commitUpdates, handleClose }) => {
  const [editedState, setEditedState] = useState(entryObj);

  const editStartTime = dateMutator => {
    const startTime = new Date(editedState.startTime.toDate().getTime());
    dateMutator(startTime);
    setEditedState({
      ...editedState,
      startTime: dateToTimestamp(startTime),
    });
  };

  const editEndTime = dateMutator => {
    const endTime = new Date(editedState.endTime.toDate().getTime());
    dateMutator(endTime);
    setEditedState({
      ...editedState,
      endTime: dateToTimestamp(endTime),
    });
  };

  return (
    <Modal show={true} onHide={handleClose} animation={false} centered>
      <Modal.Header closeButton>
        <Modal.Title>
          {categories[entryObj.categoryKey].name}{" "}
          {entryObj.textContent !== undefined && entryObj.textContent !== ""
            ? "[" + entryObj.textContent + "] "
            : ""}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Dropdown onSelect={key => setEditedState({ ...editedState, categoryKey: key })}>
          <strong>Category:</strong>
          {editedState.categoryKey === entryObj.categoryKey ? null : (
            <span style={{ color: "red", fontWeight: "bold" }}>
              {" "}
              Was: {categories[entryObj.categoryKey].name}{" "}
              <Button
                size="sm"
                onClick={() =>
                  setEditedState({
                    ...editedState,
                    categoryKey: entryObj.categoryKey,
                  })
                }
                variant="danger"
              >
                Reset
              </Button>
            </span>
          )}
          <br />
          <Dropdown.Toggle variant="success" id="dropdown-basic">
            {categories[editedState.categoryKey].name}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {Object.keys(categories).map(key => (
              <Dropdown.Item key={key} eventKey={key} active={editedState.categoryKey === key}>
                {categories[key].name}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
        <strong>Description:</strong>
        {editedState.textContent === entryObj.textContent ? null : (
          <span style={{ color: "red", fontWeight: "bold" }}>
            {" "}
            Was: {entryObj.textContent}{" "}
            <Button
              size="sm"
              onClick={() =>
                setEditedState({
                  ...editedState,
                  textContent: entryObj.textContent,
                })
              }
              variant="danger"
            >
              Reset
            </Button>
          </span>
        )}
        <br />
        <Form
          onSubmit={event => {
            event.preventDefault();
            event.stopPropagation();
          }}
        >
          <Form.Group controlId="textContent">
            <Form.Control
              value={editedState.textContent}
              onChange={event =>
                setEditedState({
                  ...editedState,
                  textContent: event.target.value,
                })
              }
            />
          </Form.Group>
        </Form>
        <strong>Start Time:</strong>
        {editedState.startTime.toMillis() === entryObj.startTime.toMillis() ? null : (
          <span style={{ color: "red", fontWeight: "bold" }}>
            {" "}
            Was: <FormattedTimestampForEditing timestamp={entryObj.startTime} />{" "}
            <Button
              size="sm"
              onClick={() =>
                setEditedState({
                  ...editedState,
                  startTime: entryObj.startTime,
                })
              }
              variant="danger"
            >
              Reset
            </Button>
          </span>
        )}
        <br />
        <Form>
          <Form.Row>
            <Form.Group as={Col} controlId="Day">
              <Form.Label>Day</Form.Label>
              <Form.Control
                onChange={event =>
                  editStartTime(startTime => startTime.setDate(event.target.value))
                }
                value={editedState.startTime.toDate().getDate()}
                disabled
              />
            </Form.Group>
            <Form.Group as={Col} controlId="Month">
              <Form.Label>Month</Form.Label>
              <Form.Control
                onChange={event =>
                  editStartTime(startTime => startTime.setMonth(event.target.value - 1))
                }
                value={editedState.startTime.toDate().getMonth() + 1}
                disabled
              />
            </Form.Group>
            <Form.Group as={Col} controlId="Year">
              <Form.Label>Year</Form.Label>
              <Form.Control
                onChange={event =>
                  editStartTime(startTime => startTime.setFullYear(event.target.value))
                }
                value={editedState.startTime.toDate().getFullYear()}
                disabled
              />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} controlId="Hour">
              <Form.Label>Hour</Form.Label>
              <Form.Control
                onChange={event =>
                  editStartTime(startTime => startTime.setHours(event.target.value))
                }
                value={editedState.startTime.toDate().getHours()}
              />
            </Form.Group>
            <Form.Group as={Col} controlId="Minute">
              <Form.Label>Minute</Form.Label>
              <Form.Control
                onChange={event =>
                  editStartTime(startTime => startTime.setMinutes(event.target.value))
                }
                value={editedState.startTime.toDate().getMinutes()}
              />
            </Form.Group>
            <Form.Group as={Col} controlId="Second">
              <Form.Label>Second</Form.Label>
              <Form.Control
                onChange={event =>
                  editStartTime(startTime => startTime.setSeconds(event.target.value))
                }
                value={editedState.startTime.toDate().getSeconds()}
              />
            </Form.Group>
            <Form.Group as={Col} controlId="zone">
              <Form.Label>&nbsp;</Form.Label>
              <Form.Control value={calculateZone(editedState.startTime)} disabled />
            </Form.Group>
          </Form.Row>
        </Form>
        {editedState.endTime === undefined ? null : (
          <span>
            <strong>End Time:</strong>
            {editedState.endTime.toMillis() === entryObj.endTime.toMillis() ? null : (
              <span style={{ color: "red", fontWeight: "bold" }}>
                {" "}
                Was: <FormattedTimestampForEditing timestamp={entryObj.endTime} />{" "}
                <Button
                  size="sm"
                  onClick={() =>
                    setEditedState({
                      ...editedState,
                      endTime: entryObj.endTime,
                    })
                  }
                  variant="danger"
                >
                  Reset
                </Button>
              </span>
            )}
            <br />
            <Form>
              <Form.Row>
                <Form.Group as={Col} controlId="Day">
                  <Form.Label>Day</Form.Label>
                  <Form.Control
                    onChange={event => editEndTime(endTime => endTime.setDate(event.target.value))}
                    value={editedState.endTime.toDate().getDate()}
                    disabled
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="Month">
                  <Form.Label>Month</Form.Label>
                  <Form.Control
                    onChange={event =>
                      editEndTime(endTime => endTime.setMonth(event.target.value - 1))
                    }
                    value={editedState.endTime.toDate().getMonth() + 1}
                    disabled
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="Year">
                  <Form.Label>Year</Form.Label>
                  <Form.Control
                    onChange={event =>
                      editEndTime(endTime => endTime.setFullYear(event.target.value))
                    }
                    value={editedState.endTime.toDate().getFullYear()}
                    disabled
                  />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} controlId="Hour">
                  <Form.Label>Hour</Form.Label>
                  <Form.Control
                    onChange={event => editEndTime(endTime => endTime.setHours(event.target.value))}
                    value={editedState.endTime.toDate().getHours()}
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="Minute">
                  <Form.Label>Minute</Form.Label>
                  <Form.Control
                    onChange={event =>
                      editEndTime(endTime => endTime.setMinutes(event.target.value))
                    }
                    value={editedState.endTime.toDate().getMinutes()}
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="Second">
                  <Form.Label>Second</Form.Label>
                  <Form.Control
                    onChange={event =>
                      editEndTime(endTime => endTime.setSeconds(event.target.value))
                    }
                    value={editedState.endTime.toDate().getSeconds()}
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="zone">
                  <Form.Label>&nbsp;</Form.Label>
                  <Form.Control value={calculateZone(editedState.endTime)} disabled />
                </Form.Group>
              </Form.Row>
            </Form>
          </span>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="secondary" onClick={() => commitUpdates(editedState)}>
          Apply
        </Button>
        <Button variant="primary" onClick={() => commitUpdates(editedState).then(handleClose)}>
          Done
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const EditButton = ({ categories, entryObj, commitUpdates, displayModal }) => (
  <span>
    <Button
      variant="primary"
      style={{ marginLeft: "0.5rem" }}
      onClick={() =>
        displayModal(
          <EditModal categories={categories} commitUpdates={commitUpdates} entryObj={entryObj} />
        )
      }
    >
      <FontAwesome.Edit />
    </Button>
  </span>
);

const calculateZone = timestamp => {
  const offset = timestamp.toDate().getTimezoneOffset();
  const isGmt = offset === 0;
  const isBst = offset === -60;
  return isGmt ? "GMT" : isBst ? "BST" : offset;
};

const FormattedTimestampForEditing = ({ timestamp }) => {
  const date = timestamp.toDate();

  const zone = calculateZone(timestamp);

  let hours = "" + date.getHours();
  let minutes = "" + date.getMinutes();
  let seconds = "" + date.getSeconds();

  if (hours.length < 2) hours = "0" + hours;
  if (minutes.length < 2) minutes = "0" + minutes;
  if (seconds.length < 2) seconds = "0" + seconds;

  return (
    <span title={timestamp.toDate().toISOString()}>
      {TimeDate.formatDate(timestamp.toDate())} {hours}:{minutes}:{seconds} {zone}
    </span>
  );
};

const TimeSince = ({ startDateTime }) => {
  const initialDuration = secondsSince(startDateTime);
  const overLimit = initialDuration > 24 * 60 * 60;

  const [duration, setDuration] = useState(initialDuration);

  useEffect(() => {
    if (!overLimit) {
      const id = setInterval(() => setDuration(secondsSince(startDateTime)), 200);
      return () => clearInterval(id);
    }
  }, [startDateTime, overLimit]);

  if (overLimit) {
    return <span>more than a day</span>;
  }
  return <Duration duration={duration} />;
};

// Extract following duplication
const ifNotZero = (num, suffix) => (num > 0 ? num + suffix : null);
const Duration = ({ duration }) => {
  const fractionalHours = Math.round((100 * duration) / 3600) / 100;

  const hours = Math.floor(duration / (60 * 60));
  const minutes = Math.floor((duration - hours * 60 * 60) / 60);

  if (hours === 0 && minutes === 0) {
    return <span title={fractionalHours}>{duration + "s"}</span>;
  }

  const hoursStr = ifNotZero(hours, "h");
  const minutesStr = ifNotZero(minutes, "m");
  return (
    <span title={fractionalHours}>
      {[hoursStr, minutesStr].filter(str => str !== null).join(" ")}
    </span>
  );
};
const IdLabel = ({ id }) => <span>{id.substring(0, 6)}</span>; //TODO: extract dupe

const StartStopButton = ({
  textContent,
  categoryKey,
  categoryObj,
  contentCollection,
  inProgressCategory,
}) => {
  const thisCategoryInProgress =
    inProgressCategory !== undefined && categoryKey === inProgressCategory.categoryKey;
  const otherCategoryInProgress = inProgressCategory !== undefined && !thisCategoryInProgress;

  const endCurrent = now =>
    contentCollection.doc(inProgressCategory.id).set({ endTime: now }, { merge: true });
  const startNew = now =>
    contentCollection.add({
      startTime: now,
      categoryKey: categoryKey,
      textContent: textContent,
    });

  const textColour =
    categoryObj.textColour === undefined ? categoryObj.colour : categoryObj.textColour;
  return (
    <Button
      style={{
        background: "none",
        border: "3px solid",
        borderColor: textColour,
        color: textColour,
        marginRight: "0.5rem",
        marginBottom: "0.5rem",
        borderRadius: "0",
      }}
      size="lg"
      onClick={() => {
        const now = timestampNow();
        if (thisCategoryInProgress) {
          endCurrent(now);
        } else if (otherCategoryInProgress) {
          endCurrent(now).then(() => startNew(now));
        } else {
          startNew(now);
        }
      }}
    >
      {thisCategoryInProgress ? (
        <span>
          <FontAwesome.Stop /> {categoryObj.name}
        </span>
      ) : otherCategoryInProgress ? (
        <span>
          <FontAwesome.Switch /> {categoryObj.name}
        </span>
      ) : (
        <span>
          <FontAwesome.Start /> {categoryObj.name}
        </span>
      )}
    </Button>
  );
};

export default TimesheetHistory;
