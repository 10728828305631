import Spinner from "react-bootstrap/Spinner";
import TimeDate from "./timeDate";
import Button from "react-bootstrap/Button";
import React from "react";

const DateSelector = ({ loadingMore, cutOffDate, cutoffActions }) => (
  <div
    style={{
      textAlign: "center",
      margin: "1em",
      fontSize: "90%",
    }}
  >
    {loadingMore ? <Spinner animation="grow" variant="success" /> : null}
    {cutOffDate !== null ? (
      <span>
        Hiding everything before {TimeDate.formatDate(cutOffDate)}{" "}
        <Button onClick={cutoffActions.showMoreAction}>Show another week</Button>{" "}
        <Button onClick={cutoffActions.showAllAction}>Show all</Button>{" "}
        <Button onClick={() => cutoffActions.resetAction()}>Hide old</Button>
      </span>
    ) : (
      <Button onClick={() => cutoffActions.resetAction()}>Hide old</Button>
    )}
  </div>
);

export default DateSelector;
